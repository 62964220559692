import ig from '../images/gallery/filter.jpg';

// BG Images
export const BG_COVER = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/_DG__Elite_1.jpg?updatedAt=1687571331199`;
export const BG_WELCOME = BG_COVER;
export const BG_COUPLE_INFO = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/backround-alternative.jpg?updatedAt=1687666046270`;
export const BG_WEDDING_INFO = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/Bernyka___Gunung.jpg?updatedAt=1688693493728`;
export const BG_COUNTING_DOWN = `https://ik.imagekit.io/basirudinansor/Gracia%20Atha/Counting%20Days%20Remind%20Me.jpg?updatedAt=1696959620472`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/basirudinansor/Gracia%20Atha/Counting%20Days%20Remind%20Me.jpg?updatedAt=1696959620472`;
export const BG_RSVP = BG_COUPLE_INFO;
export const BG_INSTAGRAM_FILTER = ig;

// still not used
export const BG_GUEST_INFO = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
export const BG_CONFIRMATION = BG_GUEST_INFO;

// Welcoming Image
// @TODO: check this image used or not?
export const IMG_PHOTO_1 = ``;
export const IMG_PHOTO_2 = ``;
export const IMG_PHOTO_3 = ``;

// Wedding Detail Image
// @TODO: check this image is used or not
export const IMG_AKAD = `https://user-images.githubusercontent.com/10141928/168337817-3d345ab6-8282-4b94-b00e-1fa68bc4d266.jpg`;
export const IMG_RECEPTION = `https://user-images.githubusercontent.com/10141928/168337834-61422ec1-eb3d-4b0a-99cb-337080e42d1d.jpg`;
export const IMG_DRESSCODE = `https://user-images.githubusercontent.com/10141928/169198604-51a5748c-b5ca-4a7b-8576-d9bf6945e0d6.jpg`;

// Couple Image
// @TODO: check this image is used or not
export const IMG_COUPLE = `https://ik.imagekit.io/invitatoid/template-bernyka/Foto%20Cover%20Ori%20Verse%20(1)_CwIRZtSZB.jpg?updatedAt=1690380914722`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
export const IMG_MAN = `https://ik.imagekit.io/basirudinansor/Gracia%20Atha/Foto%20Groom.jpg?updatedAt=1696959318468`;
export const IMG_GIRL = `https://ik.imagekit.io/basirudinansor/Gracia%20Atha/Foto%20Bride.jpg?updatedAt=1696959321332`;
export const IMG_WELCOMING_SECTION = `https://user-images.githubusercontent.com/10141928/169065994-eb456e46-d52f-48c7-bea4-bad679d56958.jpg`;
export const IMG_WISHES = BG_GUEST_INFO;

// Cover Gallery Image
// @TODO: check this image is used or not
export const IMG_GALLERY_1 = `https://ik.imagekit.io/basirudinansor/Gracia%20Atha/Foto%20Cover%20Gallery%20Potrait.jpg?updatedAt=1696959327099`;
export const IMG_GALLERY_2 = ``;
export const IMG_GALLERY_COVER = IMG_GALLERY_1;

// Logo Section
export const IMG_LOGO = `https://ik.imagekit.io/basirudinansor/Gracia%20Atha/logo.png?updatedAt=1696826465537`;
// @TODO: check this image is used or not
export const IMG_LOGO_INVERT = IMG_LOGO;

/**
 * Image Flower Assets
 * @important - IF YOU WANT TO OVERRIDE THIS ASSETS,
 * MAKE SURE YOU REPLACE WITH SAME RESOLUTION
 */
export const ASSETS_FLOWER_LEFT = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/left.png?updatedAt=1687573118921`;
export const ASSETS_FLOWER_RIGHT = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/right.png?updatedAt=1687573118777`;
export const ASSETS_ANIMAL = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/_DG__Elite_1__1_.png?updatedAt=1687613839377`;
export const ASSETS_FLOWER_COUPLE = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/flower-couple.png?updatedAt=1687664993216`;
export const ASSETS_FLOWER_WEDDING = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/flower-wedding-info.png?updatedAt=1687667945337`;

/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};

// Partner Logo
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_TIRTOWENING =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_prahasta =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';
