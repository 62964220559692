import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';
import WithAnimation from '@components/Common/WithAnimation';

function LeftContent() {
  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
      height="100%"
      zIndex="10"
    >
      <Box padding="42px" bgColor="blackAlpha.400" height="100%">
        <WithAnimation>
          <Text letterSpacing="4px" fontSize="lg" color="whiteAlpha.800">
            THE WEDDING OF
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            margin="32px 0"
            fontWeight="normal"
            size="4xl"
            lineHeight="80%"
            color="whiteAlpha.900"
            fontFamily="CustomFont"
            //textTransform="uppercase"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text fontStyle="italic" fontSize="xl" color="whiteAlpha.800">
            {`“Barang siapa yang mencintai karena Allah, membenci karena Allah, memberi karena Allah dan tidak memberi karena Allah, maka sungguh telah sempurna imannya.”`}
            <br />
            (HR. Abu Dawud dan At-Tirmidzi)
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
